.entirepage {
    font-family: 'Nunito';
    background-color: #FAF7F1;
    padding-bottom: 150px;
    padding-top: 120px;
    padding-left: 60px;
    padding-right: 60px;
}

.upper {
    width: fit-content;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-family: "Nunito";
    font-weight: 600;
}

.swag {
    width: fit-content;
    margin-right: 8px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.56);
    cursor: pointer;
}

.greater {
    width: fit-content;
    margin-left: 8px;
    font-size: 10px;
}

.swag:hover {
    color: #0F143A;
}

.OrderForm {
    width: fit-content;
    margin-top: 44px;
    color: #0F143A;
}

.heading {
    margin-top: 52px;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 40px;
}

a {
    margin: 0px !important;
    padding: 0px !important;
}

.addingbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addicon {
    color: #050505;
    margin-right: 12px;
    width: 0.6em;
}

.button {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: #FFFFFF;
    border-radius: 8px;
    color: #050505;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    letter-spacing: 0.016em;
    margin-bottom: 52px;
    margin-right: 36px;
    cursor: pointer;
    border: 2px solid #191818;
    outline: none;
}

.displaytable {
    width: 100%;
    border-spacing: 0px;
    border-radius: 8px;
    align-items: center;
    border-top: 1px solid #A3A3A3;
    border-right: 1px solid #A3A3A3;
    border-left: 1px solid #A3A3A3;
}

.displaytable tr:first-child th:first-child {
    border-top-left-radius: 8px;
}

.displaytable tr:first-child th:last-child {
    border-top-right-radius: 8px;
}

.displaytable tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.displaytable tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

th {
    padding: 10px;
    background-color: #F5F5F5 !important;
    color: #141313 !important;
    border-bottom: 1px solid #A3A3A3;
}

td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #A3A3A3;
}

tr {
    border-bottom: 1px solid #A3A3A3;
}

.testimonialrow {
    background-color: white;
    color: black;
    height: 90px;
}

.testimonialrowhead {
    height: 50px;
}

.desc{
    padding: 40px 30px;
    width: 340px;
}

.logos {
    height: 78px;
    max-width: 300px;
}

.edit {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #757474;
    cursor: pointer;
}

td:last-child {
    width: 84px;
}

a {
    text-decoration: none;
    all: unset;
}