.cardscontainer {
    display: grid;
    /* grid-template-columns: auto auto auto auto; */
    column-gap: 60px;
    grid-template-columns: repeat(auto-fit, 340px);
    row-gap: 60px;
    margin-top: 52px;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
}

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0F143A;
    border: 1px solid black;
    width: 340px;
    height: 200px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF;
    border: 4px solid #0F143A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.cards img {
    width: 96px;
    height: 88px;
    margin-bottom: 24px;
}