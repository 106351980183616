.AdminEntry {
    background-color: #FAF7F1;
    padding-bottom: 150px;
    text-align: center;
    padding-top: 140px;
    font-family: 'Nunito';
}

.toggle {
    display: flex;
    flex-direction: row;
    border: 1px solid #0F143A;
    border-radius: 12px;
    text-align: center;
    align-items: center;
    width: 560px;
    height: 60px;
    margin-left: calc(50% - 280px);
    cursor: pointer;
}

.toggle h4 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #191818;
}

.website{
    align-items: center;
    width: 140px;
    height: 60px;
    background-color:#0F143A;
    border-radius: 12px 0px 0px 12px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.websiteunactive{
    align-items: center;
    width: 140px;
    height: 60px;
    border-radius: 12px 0px 0px 12px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.website h4{
    color: white;
}

.business{
    align-items: center;
    width: 140px;
    height: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.businessactive{
    align-items: center;
    width: 140px;
    height: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #0F143A;
}

.businessactive h4{
    color: white;
}

.clients{
    align-items: center;
    width: 140px;
    height: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.partners{
    align-items: center;
    width: 140px;
    height: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
}