.clientpage {
    padding-top: 140px;
    background-color: #FAF7F1;
    padding-bottom: 150px;
    font-family: 'Nunito';
    text-align: center;
    display: flex;
    justify-content: center;
}

.heading{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.items {
    text-align: left;
}

.items p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin-top: 24px;
}

#gap {
    margin-top: 14px;
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 864px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    margin-bottom: 0px;
    padding-right: 16px;
}

#gap::placeholder {
    color: rgba(52, 52, 52, 0.64);
}

#gap::-webkit-outer-spin-button,
#gap::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.images {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.imglogo{
    height: 78px;
    width: fit-content;
    max-width: 350px;
}

#file {
    cursor: pointer;
    margin-top: 14px;
    font-size: 18px;
    line-height: 116%;
    color: #0F143A;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
}

#file::-webkit-file-upload-button {
    width: 230px;
    margin-right: 20px;
    border-radius: 12px;
    height: 52px;
    cursor: pointer;
}

.button {
    margin-top: 36px;
}

.bttn {
    border: 1px solid rgba(0, 22, 54, 0.92);
    background: rgba(15, 20, 58, 0.08);
    cursor: pointer;
    padding: 10px 24px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    font-family: 'Nunito', sans-serif;
}

.bttn:hover {
    background: #0F143A;
    color: white;
}