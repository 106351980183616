.productcontainer {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    background-color: #FAF7F1;
    font-family: 'Nunito';
    padding-bottom: 150px;
}

.upper {
    margin-bottom: 52px;
    width: fit-content;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-family: "Nunito";
    font-weight: 600;
}

.swag {
    width: fit-content;
    margin-right: 8px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.56);
    cursor: pointer;
}

.greater {
    width: fit-content;
    margin-left: 8px;
    font-size: 10px;
}

.swag:hover {
    color: #0F143A;
}

.productdesc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rightproductdesc {
    display: flex;
    flex-direction: row;
}

.icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    font-size: 12px;
    z-index: 1;
}

.bttn {
    position: relative;
    padding: 10px 8px 10px 26px;
    background: #FFFFFF;
    border: 2px solid #191818;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #050505;
    font-family: 'Nunito', sans-serif;
}

.details {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin-bottom: 20px;
}

.iddetails {
    text-align: left;
    /* margin-bottom: 36px; */
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #474646;
}

.display {
    display: flex;
    justify-content: flex-start;
}

.imagedisplay {
    width: 192px;
    height: 160px;
    background: #FFFFFF;
    border: 2px solid #191818;
    border-radius: 8px;
}

.head {
    margin-top: 60px;
    margin-bottom: 44px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
    text-align: left;
}

.items {
    text-align: left;
}

.itemhead {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
    margin-right: 32px;
    min-width: 216px;
}

.first {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
}

.select {
    border: 2px solid rgba(0, 22, 54, 0.92) !important;
    border-radius: 8px;
    width: 448px;
    font-size: 20px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 56px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 12px;
}

.select:invalid {
    color: rgba(52, 52, 52, 0.64) !important;
}

.second {
    display: flex;
    flex-direction: column;
}

.deschead {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
    margin-bottom: 24px;
}

#desc {
    border: 2px solid rgba(0, 22, 54, 0.92);
    border-radius: 8px;
    width: 694px;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #0F143A;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    height: 140px;
    padding-top: 16px;
    padding-right: 24px;
    margin-bottom: 32px;
}

#desc::placeholder {
    color: rgba(52, 52, 52, 0.64)
}

#gap {
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 448px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    margin-bottom: 0px;
    padding-right: 16px;
}

#gap::placeholder {
    color: rgba(52, 52, 52, 0.64);
}

.togglebutton {
    margin-left: -60px;
}

.pricehead {
    margin-top: 28px;
    margin-bottom: 12px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
    text-align: left;
}

.pricesubhead {
    margin-top: 32px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
}

.pricedetails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

#gaphalf {
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 210px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    padding-right: 50px;
}

.rupeeicon {
    font-weight: 400;
    font-size: 20px;
    position: absolute;
    margin-top: 12px;
    margin-left: 20px;
    color: #0F143A;
}

#gaphalfirst {
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 210px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 40px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    padding-right: 16px;
    margin-right: 42px;
}

.plus {
    margin-top: 6px;
    margin-right: 40px;
    color: #0F143A;
}

.percenticon {
    font-weight: 300;
    font-size: 24px;
    position: absolute;
    margin-left: 500px;
    margin-top: 10px;
    color: #0F143A;
}

#gaphalf::placeholder {
    color: rgba(52, 52, 52, 0.64);
}

#gap::-webkit-outer-spin-button,
#gap::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#pricegap {
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 540px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 40px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    margin-bottom: 0px;
    padding-right: 60px;
}

#pricegap::placeholder {
    color: rgba(52, 52, 52, 0.64);
}


.variantbutton {
    width: 686px;
    height: 72px;
    background: #F5F5F5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #A3A3A3;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 2px solid #191818;
    cursor: pointer;
}

.variantbox {
    margin-top: 44px;
    padding: 32px 32px 40px;
    width: 686px;
    background: #FFFFFF;
    border: 2px solid #191818;
    border-radius: 12px;
}

.variantname {
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #474646;
    margin-bottom: 32px;
}

.third {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
}

.varianthead {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #474646;
    min-width: 176px;
}