.categorycontainer {
    font-family: 'Nunito',sans-serif;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 500;
    width: 900px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #191818;
    border-radius: 12px;
    padding-bottom: 36px;
}

.closebtn {
    margin-top: 20px;
    margin-bottom: 24px;
    margin-left: 850px;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.details {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin-bottom: -24px;
}

.buttondisplay{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 32px;
}

.icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    font-size: 12px;
    z-index: 1;
}

.bttn {
    position: relative;
    padding: 10px 8px 10px 26px;
    background: #FFFFFF;
    border: 2px solid #191818;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #050505;
    font-family: 'Nunito', sans-serif;
}

.heading{
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}

.heading::-webkit-scrollbar-track {
    border: none;
    margin: 0px 0px 0px 0px;
    background-color: transparent;
}

.heading::-webkit-scrollbar {
    width: 4px;
    margin-left: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.heading::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #0F143A;
    border: 1px solid rgba(15, 20, 58, 0.08);
}

.items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
}

.itemhead {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #141313;
    margin-right: 32px;
    min-width: 176px;
}

.first {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
}

#gap {
    margin-top: 14px;
    font-size: 18px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 52px;
    width: 360px;
    border-radius: 8px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 2px solid rgba(15, 20, 58, 0.8);
    margin-bottom: 0px;
    padding-right: 16px;
}

#gap::placeholder {
    color: rgba(52, 52, 52, 0.64);
}

.pricedetails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.sizechart{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

#gap::-webkit-outer-spin-button,
#gap::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}