.entirepage {
  font-family: 'Nunito';
  background-color: #FAF7F1;
  padding-bottom: 120px;
  padding-top: 120px;
  padding-left: 60px;
  padding-right: 60px;
  min-height: 100vh;
}

.loginheading{
  font-size: 36px;
  font-weight: 700;
}

form {
  margin-top: 36px;
}

#labelname{
  font-size: 20px;
  font-weight: 600;
}

/* Full-width inputs */
#textinput , #passwordinput{
  width: 1000px;
  padding: 12px 20px;
  margin: 12px 0px;
  display: flex;
  border: 1px solid #0F143A;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Nunito',sans-serif;
  color: #0F143A;
  font-size: 18px;
  outline: none;
}

/* Set a style for all buttons */
button {
  background-color: #0F143A;
  color: #FFFFFF;
  padding: 14px 20px;
  margin: 8px 0;
  border: 1px solid #0F143A;
  outline: none;
  cursor: pointer;
  width: 120px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 12px;
  margin-right: 16px;
}