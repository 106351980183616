@import url('https://fonts.googleapis.com/css2?family=Gamja+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.popup {
    position: fixed;
    z-index: 500;
    width: 440px;
    height: 400px;
    background-color: #FAF7F1;
    border: 4px solid rgba(0, 22, 54, 0.92);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    transition: all 0.5s ease-out;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.cross {
    position: absolute;
    right: 32px;
    top: 30px;
    cursor: pointer;
}

.shake {
    margin-top: 96px;
}

.thanking {
    margin-bottom: 36px;
}

.thanking h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
}

.innerbox {
    background: #FFFFFF;
    border: 1px solid rgba(15, 20, 58, 0.4);
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 50px;
    margin-right: 50px;
}

.innerbox p {
    padding: 16px 24px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
}

@media only screen and (max-width: 640px) {
    .popup {
        width: 320px;
        height: 320px;
    }

    .cross {
        position: absolute;
        right: 20px;
        top:20px;
        cursor: pointer;
    }
    
    .shake {
        margin-top: 64px;
    }
    
    .thanking {
        margin-bottom: 36px;
    }
    
    .thanking h1 {
        font-family: 'Nunito', sans-serif;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 14px;
    }
    
    .innerbox {
        background: #FFFFFF;
        border: 1px solid rgba(15, 20, 58, 0.4);
        box-sizing: border-box;
        border-radius: 12px;
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .innerbox p {
        padding: 10px 15px;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.04em;
        color: rgba(15, 20, 58, 0.8);
    }
}