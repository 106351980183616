.switch {
    position: relative;
    display: block;
    width: 44px;
    height: 24px;
    /* background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px); */
    /* background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px); */
    border-radius: 16px;
    /* box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05); */
    cursor: pointer;
    box-sizing: content-box;
}

.switchinput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
}

.switchlabel {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #D1D1D1;
    border-radius: inherit;
    /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15); */
    box-sizing: content-box;
}

.switchlabel:before,
.switchlabel:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
}

.switchlabel:before {
    content: attr(data-off);
    right: 11px;
    color: black;
}

.switchlabel:after {
    content: attr(data-on);
    left: 11px;
    color: black;
    opacity: 0;
}

.switchinput:checked~.switchlabel {
    background: #243F67;
    /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2); */
}

.switchinput:checked~.switchlabel:before {
    opacity: 0;
}

.switchinput:checked~.switchlabel:after {
    opacity: 1;
}

.switchhandle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
}

.switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    /* margin: -6px 0 0 -6px; */
    /* width: 12px; */
    /* height: 12px; */
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    /* border-radius: 6px; */
    /* box-shadow: inset 0 1px rgba(0, 0, 0, 0.02); */
}

.switchinput:checked~.switchhandle {
    left: 22px;
    /* box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2); */
}

.icon {
    position: absolute;
    color: rgba(19, 39, 69, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

/* Transition
========================== */
.switchlabel,
.switchhandle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}